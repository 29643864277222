import React, { Fragment } from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <Fragment>
    <Seo title="404: Sayfa Bulunamadı" />
    <div className="section">
      <div className="wrap">
        <h1>404: Sayfa Bulunamadı</h1>
        <Link to="/" className="btn nobg">
          Anasayfa
        </Link>
      </div>
    </div>
  </Fragment>
)

export default NotFoundPage
